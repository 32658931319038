<template>
	<div class="main">
		<div class="nav">

			<span style="margin-left: -3px;">
				太机脑智慧环
			</span>
			<span style="margin-left: -3px;">
				境孪生云平台
			</span><!-- 太机脑生物&nbsp;&nbsp;环境云平台 -->
		</div>
		<div class="nav_btn">
			<div class="btn_left">

				<a href="/">
					<div class="btn_list listActive">主页</div>
				</a>
				<router-link to="/data_index">
					<div class="btn_list">数据中心</div>
				</router-link>

				<router-link to="/data_screen">
					<div class="btn_list">生长监测</div>
				</router-link>
			</div>

		</div>
		<div class="content">

			<div class="centerBox">
				<div class="baseBox centerMainBox1" style="height:70%">
					<div class="baseBoxLeft left">
						<div class="boxTitle">二氧化碳浓度</div>
						<img src="../assets/main/img/left.png" class="first_border" alt="">
						<div class="firstBox">

							<div class="boxRader" id="mains"></div>
							<!-- <div class="voice_animation">
										<canvas id="canvas">Your browser can not support canvas</canvas>
									</div> -->
							<div class="progress">
								<span class="disease">浓度：0.03%</span>
								<!-- <i class="counter-value">85</i>% -->
								<!-- <span class="similar">浓度:0.02</span> -->
								<img src="../assets/main/img/progress.gif" alt="">
							</div>
							<div class="about_illness">
								<div class="symptom">
									<div class="symptom_title" style=" color: #0EFCFF;">&nbsp;</div>
									<div class="symptom_content">防止出现二氧化碳饥饿，大多在上午10点之前通风
									</div>
								</div>
								<div class="prevent">
									<div class="prevent_title" style=" color: #0EFCFF;">&nbsp;</div>

									<div class="prevent_content">
										阴雨天气通风时间在中午12点到下午2点之间进行
									</div>
								</div>
							</div>


						</div>
					</div>
					<div class="baseBoxRight right">

						<!-- <a class="navLeft" href="./mapDetails.html"><img style="" src="../assets/main/img/fanhui.png" alt=""></a>
	-->
						<!-- 地图 -->
						<div class="maps">
							<img class="land_level" src="../assets/main/img/3d-top1.png" alt="">
							<img class="land_level-left" src="../assets/main/img/3d-left.png" alt="">
							<img class="land_level-right" src="../assets/main/img/3d-right.png" alt="">

							<div class="land_box1"></div>
							<div class="land_box2"></div>
							<div class="land_box3"></div>
							<div class="land_box4"></div>
							<div class="plant"></div>
							<div class="soil_data"></div>
							<div class="weather_info"></div>
							<!-- <div class="plant_info"></div> -->
						</div>
					</div>
				</div>


				<!-- 左侧下部 -->
				<div class="baseBox centerMainBox2" style="height:30%;">
					<img src="../assets/main/img/down2.png" class="first_border" alt="">
					<div class="boxTitle2" style="width:26%;">统计数据</div>
					<div class="boxTitle2" style="width:26%;margin-left:22vw;margin-top: -1.4vw;">生长数据</div>
					<div class="boxTitle2" style="width:26%;margin-left:53vw;margin-top: -1.4vw;">气象数据</div>
					<div class="leftBottom">
						<div class="land_data">
							<p>10cm 温度30湿度67</p>
							<p>20cm 温度30湿度67</p>
							<p>30cm 温度30</p>
						</div>

					</div>
					<div class="right_box">
						<div class="grow_data">
							<img src="../assets/main/img/tree_pic.png" alt="">
							<p>生长周期：8周</p>
							<p>黏土</p>
							<p>10cm黏土：22.88</p>

							<i></i>
							<div class="specialistSuggest">
								<div>温馨提示：</div>
								<div>不要忘记给小生命浇水噢</div>
							</div>
							<div class="fertilizationSuggest">
								<div>该生长周期施肥建议：</div>
								<div>氮肥:1.3-1.5kg</div>
								<!-- <div>钾肥含量：1.5-1.7kg</div> -->
							</div>
						</div>
						<div class="weather_data">
							<div class="weather_text text_one">
								<span>温度：24℃</span>
								<span>湿度：45%</span>
								<span>风向：西北风</span>
								<span>风速：2m/s</span>
							</div>
							<!-- <div class="weather_text text_two">
									<span>降雨量：0mm</span>
									<span>蒸发量：0.326mm/h</span>
									<span>气压：0.326MPa</span>
								</div> -->
						</div>

					</div>

				</div>


			</div>
			<div class="leftBox right" style="width:22%">
				<div class="baseBox baseBox1" style="margin-bottom:15px;height:40%">
					<img src="../assets/main/img/right.png" class="hardware" alt="">
					<div class="csbaseBox1">
						<div class="boxTitle">硬件设备展示</div>
						<ul class="boxLis">
							<li class="left active">无人巡检</li>
							<li class="left">传感器</li>

							<li class="left">报警器</li>
							<!-- <li class="left">监控摄像头</li>
									<li class="left">土壤检测仪</li> -->
						</ul>
						<div class="content1">
							<ul>
								<li style="display: block;" class="contList">
									<!-- 四个角描边 stat -->
									<div class="leftTopLine1"></div>
									<div class="leftTopLine2"></div>
									<div class="rightTopLine1"></div>
									<div class="rightTopLine2"></div>
									<div class="leftBottomLine1"></div>
									<div class="leftBottomLine2"></div>
									<div class="rightBottomLine1"></div>
									<div class="rightBottomLine2"></div>

									<div class="equipment_pic">
										<img class="first" src="../assets/main/img/robokit-blue2.png" alt="">
										<img class="second" style="width: 100%; height: 100%;"
											src="../assets/main/img/oxygen.jpg" alt="">
										<!-- <img class="third" src="img/camera.png" alt="">
												<img class="fourth" src="img/detector.png" alt=""> -->
									</div>
								</li>

							</ul>

						</div>
						<!-- <div class="liSpan">介绍</div>
								<p class="liP">
									如需商用，请联系qq:863512936</p> -->
					</div>
				</div>
				<div class="baseBox baseBox2" style="margin-bottom:15px;height:29.5%">
					<img src="../assets/main/img/right2.png" alt="">
					<!-- 四个角描边 end -->
					<div class="boxTitle2">PH酸碱度</div>
					<div class="irrigate_data">
						<div class="irrigate_top">
							<div class="centerList">
								<div class="centerListFont">PH值</div>
								<div class="centerListNum">
									<span class="">6.3</span>
								</div>
							</div>
							<!-- <div class="centerList">
										<div class="centerListFont">灌溉压力（MPa）</div>
										<div class="centerListNum">
											<span class="">0.29</span>
										</div>
									</div> -->
						</div>
						<div class="irrigate_bottom">

							<!-- <div class="specialistSuggest">
									<div>温馨提示：</div>
									<div>不要忘记给小生命浇水噢</div>
								</div> -->
							<!-- <p class="liP">
										当土壤pH值介于6.0和7.5之间，磷、轉、钾、镁等养分最容易被植物吸收。 
										在pH值低，这些养分变得不易溶解，相对来说，不易被植物吸收。
										土壤pH值高也降低营养成分的吸收。如果土壤碱性大于pH值8，磷、
										铁和许多微量元素不溶解，不能被植物吸收。</p> -->
							<div class="liP">
								<div>温馨提示：</div>
								<div>当土壤PH值介于6.0和7.5之间，养分最容易被植物吸收。
									在PH值低或者高，这些养分都不易被植物吸收。</div>
							</div>
						</div>
					</div>
				</div>
				<div class="baseBox baseBox3" style="height:28%">
					<img src="../assets/main/img/right3.png" alt="">
					<!-- 四个角描边 end -->
					<div class="boxTitle2">数据日志</div>
					<!-- <div class="data_day" id="demo" > -->
					<div class="data_day" id="demo" style="width:100%;overflow:hidden;height: 75%;">
						<table style="text-align: left;margin-left:10%;height:80%;width: 90%;">
							<tbody id="demo1">
								<!-- <tr class="head">
	                                    <td>编号</td>
	                                    <td>数据类型</td>
	                                    <td>数据值</td>
	                                    <td>时间</td>
	                                </tr> -->
								<tr>
									<td>u78</td>
									<td>传感器数据</td>
									<td>2024年5月7日</td>
								</tr>

								<tr>
									<td>u48</td>
									<td>传感器数据</td>
									<td>2024年5月8日</td>
								</tr>

								<tr>
									<td>u78</td>
									<td>传感器数据</td>
									<td>2024年5月7日</td>
								</tr>
								<tr>
									<td>812</td>
									<td>监视器数据</td>
									<td>2024年5月8日</td>
								</tr>

								<tr>
									<td>s07</td>
									<td>控制器数据</td>
									<td>2024年5月9日</td>
								</tr>
								<tr>
									<td>u94</td>
									<td>传感器数据</td>
									<td>2024年5月7日</td>
								</tr>
								<tr>
									<td>842</td>
									<td>监视器数据</td>
									<td>2024年5月8日</td>
								</tr>
								<tr>
									<td>u45</td>
									<td>传感器数据</td>
									<td>2024年5月7日</td>
								</tr>
								<tr>
									<td>893</td>
									<td>监视器数据</td>
									<td>2024年5月9日</td>
								</tr>
								<tr>
									<td>037</td>
									<td>传感器数据</td>
									<td>2024年5月8日</td>
								</tr>

								<tr>
									<td>834</td>
									<td>监视器数据</td>
									<td>2024年5月9日</td>
								</tr>
								<tr>
									<td>257</td>
									<td>传感器数据</td>
									<td>2024年5月7日</td>
								</tr>
								<tr>
									<td>835</td>
									<td>监视器数据</td>
									<td>2024年5月8日</td>
								</tr>
								<tr>
									<td>097</td>
									<td>传感器数据</td>
									<td>2024年5月7日</td>
								</tr>
								<tr>
									<td>057</td>
									<td>传感器数据</td>
									<td>2024年5月9日</td>
								</tr>
								<tr>
									<td>226</td>
									<td>传感器数据</td>
									<td>2024年5月8日</td>
								</tr>
								<tr>
									<td>027</td>
									<td>传感器数据</td>
									<td>2024年5月7日</td>
								</tr>
							</tbody>
							<tbody id="demo2" style="text-align: left;margin-left:10%"></tbody>
						</table>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
	import '../assets/main/css/reset.css';
	import '../assets/main/css/new_index.css';

	import echarts from 'echarts'
	export default {

		components: {

		},

		data() {
			return {

			}
		},
		filters: {

		},
		created() {
			//  this.tableHeight = window.innerHeight - 500
		},
		methods: { // 修改table tr行的背景色

			drawLine() {

				var HYChart1 = echarts.init(document.getElementById('mains'));

				var option;

				option = {
					series: [{
						name: 'Pressure',
						type: 'gauge',
						max: 0.1,
						splitNumber: 5,
						axisLine: {
							lineStyle: {
								width: 10,
								color: [
									[0.2, '#ff5500'],
									[1, '#68c828']
								]
							}
						},
						pointer: {

							length: '60%',
							width: 4,

							itemStyle: {
								color: 'auto'
							}
						},
						splitLine: {
							length: 13,
							lineStyle: {
								width: 2,
								color: '#999'
							}
						},
						detail: {
							/* fontSize: 15,
				            valueAnimation: true, */
							fontSize: 20,
							formatter: '{value} %',
							color: 'auto'
						},

						data: [{
							value: 0.03,
							/* name: 'SCORE' */
						}]
					}]
				};

				option && HYChart1.setOption(option);

			}
			 
		}, // methods

		mounted() {
			this.drawLine();

		}

	}
</script>



<style lang="scss">
	/* ---public---- */

	/* ---public---- */
	.boxRader {
		position: relative;
		width: 100%;
		height: 60%;
	}

	.centerBox {
		width: 75%;
		margin-right: 15px;
		background: transparent;
	}

	.boxFont {
		font-size: 1.2vw;
	}

	.centerMainBox1 {
		width: 100%;
		height: 60.5%;
	}

	.centerMainBox2 {
		width: 100%;
		height: 42%;
		position: relative;
		margin-top: 15px;
	}

	.centerMainBox2 .first_border {
		position: absolute;
		width: 100%;
		height: 100%;
	}

	.boxTitle2 {
		width: 100%;
		height: 10%;
		font-size: .6vw;
		text-align: center;
		line-height: 10%;
		margin-top: 1vw;
		color: #0efcff;
	}

	.contList {
		position: relative;
		width: 70%;
		height: 8vw;
		margin: 1vw auto 0;

	}

	.content1 li {
		display: none;
	}

	.baseBox {
		width: 100%;
		border: none;
		background: none;
	}

	.boxTitle {
		font-size: .8vw;
		width: 38%;
		margin-top: 1vw;
		text-align: center;
	}

	.right {
		float: right;
	}

	.baseBoxLeft {
		width: 30%;
		height: 100%;
		position: relative;
	}

	.baseBoxRight {
		width: 68%;
		height: 100%;
	}

	.baseBoxLeft .first_border {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}


	.baseBox1,
	.baseBox2,
	.baseBox3 {
		position: relative;
	}

	.baseBox1 .hardware,
	.baseBox2 img,
	.baseBox3 img {
		position: absolute;
		width: 100%;
		height: 100%;
	}

	.csbaseBox1 {
		position: relative;
		z-index: 999;
	}

	#canvas {
		display: block;
		margin: 0 auto;
		width: 10vw;
		height: 5vw;
	}

	.progress {
		position: relative;
		margin-top: -25px;
	}

	.progress img {
		width: 12.5vw;
		height: 2vw;
		position: absolute;
		top: .4vw;
		right: -.2vw;
	}

	.progress .disease {
		position: absolute;
		top: .7vw;
		left: .5vw;
		color: #0efcff;
		font-size: .8vw;
	}

	.progress .similar {
		position: absolute;
		top: .8vw;
		left: 3vw;
		color: #0efcff;
		font-size: .5vw;
	}

	/* 返回图标 */
	.navLeft {
		position: absolute;
		top: 5%;
		left: 35%;
		width: 3.5%;
		height: 8%;
		z-index: 999;
	}

	.navLeft>img {
		width: 100%;
		height: 100%;
	}
</style>
